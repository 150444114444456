import React from 'react';
import CompanyVisionNew from '../components/CompanyVisionNew';
// import ContactForm from '../components/ContactForm';
import Contacts from '../components/Contacts';

const ContactUsPage = () => {
  return (
    <>
      <CompanyVisionNew />
      {<Contacts/>}
    </>
  );
};

export default ContactUsPage;
