import React, { useState } from 'react';
import axios from 'axios'; // Axios for API integration
import ContactInfo from '../components/ContactInfo';
import Loader from '../components/loader';
import '../styles/css/ApplyNow/ApplyNow.css';

interface FormData {
    name: string;
    surname: string;
    email: string;
    reason: string;
    position: string;
    type: string;
}

const ApplyNow: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState('');

    const [formData, setFormData] = useState<FormData>({
        name: '',
        surname: '',
        email: '',
        reason: '',
        position: '',
        type: '',
    });

    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);
    const [showAlertPopup, setShowAlertPopup] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        if (file && allowedTypes.includes(file.type)) {
            // console.log(file.type);
            setFile(file);
            setFileName(file.name);
        } else {
            alert('Please upload Only a PDF or DOC/DOCX file.');
            event.target.value = '';
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const fileInput = document.getElementById('resumeUpload') as HTMLInputElement;
        if (!fileInput.files || fileInput.files.length === 0) {
            alert('Please upload your resume.');
            return;
        }
        setShowConfirmationPopup(true); // Show confirmation popup
    };

    // Confirm submission - this is where the form data will be submitted to the backend API
    const confirmSubmission = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('surname', formData.surname);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('position', formData.position);
        formDataToSend.append('reason', formData.reason);
        if (file) {
            formDataToSend.append('resume', file);
        }

        try {
            // Submit the form data and file to the backend API
            // console.log(formDataToSend);
            // console.log('starting loading');
            setLoading(true);
            await axios.post('https://ripple-nexus-website.onrender.com/api/submit-application', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setLoading(false);
            // console.log("loading ended");
            setShowConfirmationPopup(false); // Hide confirmation popup
            //clearing old data
            setFormData({
                name: '',
                surname: '',
                email: '',
                reason: '',
                position: '',
                type: '',
            });

            //clearing file input
            const fileInput = document.getElementById('resumeUpload') as HTMLInputElement;
            fileInput.value = '';
            setFileName('');
            setShowAlertPopup(true); // Show success alert
        } catch (error) {
            console.error('Error submitting application:', error);
        }
    };

    const closeAlertPopup = () => {
        setShowAlertPopup(false);
    };

    return (
        <>
            <div id='contact-us' className="contact-container">
                <h1 className="Interested-in-our-mission">
                    Interested in our mission?
                </h1>
                <div className='apply-now-cover'>
                    <h2 className='Apply-now'>
                        Apply now
                    </h2>
                    <p className='Interested-in-our-mission-p'>Ready to take the next step in your career? We’re excited to hear from talented, passionate individuals who are eager to make an impact. Fill out the application form below, and let’s explore how you can become a valuable part of our team.</p>
                </div>
                <div className="contact-content">
                    <form className="contact-form" encType="multipart/form-data" onSubmit={handleSubmit}>
                        <h2 className='sendMessage'>Send us a Message</h2>
                        <label htmlFor="name">First Name</label>
                        <input type="text" id="name" placeholder="First Name" value={formData.name} onChange={handleChange} required />

                        <label htmlFor="surname">Surname</label>
                        <input type="text" id="surname" placeholder="Surname" value={formData.surname} onChange={handleChange} required />

                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" placeholder="Email" value={formData.email} onChange={handleChange} required />

                        <label htmlFor="position">Position you are applying for?</label>
                        <select id="position" value={formData.position} onChange={handleChange} required>
                            <option value="" disabled defaultChecked>Select a position</option>
                            {['Full Stack Developer', 'Backend Developer', 'Frontend Developer', 'Logo and Branding Designer', 'Graphic Designer', 'AI Chatbot Developer', 'Software Developer'].map((opt, idx) => {
                                return (<option key={2315 + idx} value={opt}>{opt}</option>)
                            })}
                            {/* <option value="developer">Developer</option>
                            <option value="designer">Designer</option>
                            <option value="manager">Manager</option>
                            <option value="analyst">Analyst</option> */}
                        </select>

                        <label htmlFor="reason">Why should we hire you?</label>
                        <p className='form-smaller-texts'>Cover letter</p>
                        <textarea id="reason" placeholder="Write something here" value={formData.reason} onChange={handleChange} rows={3} required></textarea>

                        <label htmlFor="resumeUpload" className="custom-file-upload" style={{ color: "#457fd7" }}>
                            {fileName ? `File: ${fileName}` : 'Upload Resume'}
                        </label>
                        <input
                            type="file"
                            id="resumeUpload"
                            name="resume"
                            accept=".doc,.docx,.pdf"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <p className='form-smaller-texts' style={{ marginLeft: "1.5em" }}>Doc, Docx, pdf (10MB)</p>
                        <button className='form-submit-button' type="submit">Apply</button>
                    </form>
                    <ContactInfo />
                </div>

                {showConfirmationPopup && (
                    <>
                        <div className="popup">
                            <div className="popup-content">
                                <h2 style={{ color: "black" }}>Confirm Submission</h2>
                                <p style={{ color: "black" }}>Are you sure you want to submit the form?</p>
                                <div className='button-group'>
                                    <button onClick={confirmSubmission}>Yes</button>
                                    <button onClick={() => setShowConfirmationPopup(false)}>No</button>
                                </div>
                            </div>
                        </div>
                        {
                            loading && (<Loader />)
                        }

                    </>


                )}

                {showAlertPopup && (
                    <div className="alert-popup">
                        <div className="alert-popup-content">
                            <p style={{ color: "black" }}>Your request has been submitted!</p>
                            <button onClick={closeAlertPopup}>Close</button>
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default ApplyNow;
