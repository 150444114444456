import React from 'react';

const ContactInfo: React.FC = () => {
    return (
        <>
            <div className="contact-info">
                <h3 className='contact-info-header'>Contact Information</h3>
                <div className='contact-lines'>

                    <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.45508 10.5206C4.45508 6.04519 7.24428 2.42554 10.6929 2.42554C14.1416 2.42554 16.9308 6.04519 16.9308 10.5206C16.9308 15.343 12.992 21.9925 11.3791 24.5251C11.0226 25.0802 10.3721 25.0802 10.0157 24.5251C8.39383 21.9925 4.45508 15.343 4.45508 10.5206ZM8.46484 10.5205C8.46484 12.1164 9.4629 13.4116 10.6926 13.4116C11.9224 13.4116 12.9204 12.1164 12.9204 10.5205C12.9204 8.92462 11.9224 7.62941 10.6926 7.62941C9.4629 7.62941 8.46484 8.92462 8.46484 10.5205Z" fill="white" />
                    </svg>
                    <p style={{ width: "80%", textAlign: "left" }}>
                    Sector 62, Noida, Uttar Pradesh-201311
                    </p>
                </div>
                <div className='contact-lines'>
                    <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M17.1367 18.6112L14.8732 18.2759C14.3297 18.1949 13.795 18.4378 13.4118 18.935L11.7721 21.0629C9.25027 19.3976 7.18287 16.7262 5.89966 13.4419L7.54823 11.3025C7.93141 10.8052 8.11855 10.1114 8.05617 9.40595L7.79775 6.49172C7.69081 5.32372 6.93336 4.44482 6.02442 4.44482H4.48278C3.47581 4.44482 2.63816 5.53188 2.70054 6.83866C3.17283 16.7147 9.25918 24.6016 16.8604 25.2145C17.8674 25.2954 18.7051 24.2084 18.7051 22.9016V20.901C18.714 19.733 18.0367 18.75 17.1367 18.6112Z" fill="white" />
                    </svg>

                    <p>+91 7599756826</p>
                </div>
                <div className='contact-lines'>
                    <svg width="22" height="22" viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg" fill="white" version="1.1">
                        <path stroke="#fff" strokeLinejoin="round" strokeWidth="12" d="M22 57.265V142c0 5.523 4.477 10 10 10h24V95.056l40 30.278 40-30.278V152h24c5.523 0 10-4.477 10-10V57.265c0-13.233-15.15-20.746-25.684-12.736L96 81.265 47.684 44.53C37.15 36.519 22 44.032 22 57.265Z"></path>
                    </svg>

                    <p>contact@ripplenexus.tech</p>

                </div>
                {/* <div className='contact-lines'>
                    <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.23305 3.94238H15.1442C15.6344 3.94238 16.0354 4.46278 16.0354 5.09882V7.4117C16.0354 8.04775 15.6344 8.56814 15.1442 8.56814H6.23305C5.74293 8.56814 5.34193 8.04775 5.34193 7.4117V5.09882C5.34193 4.46278 5.74293 3.94238 6.23305 3.94238ZM4.4507 9.7245H16.9264C18.4056 9.7245 19.5997 11.2741 19.5997 13.1938V17.8196C19.5997 19.0917 18.7977 20.1325 17.8175 20.1325H16.0353V22.4453C16.0353 23.7174 15.2333 24.7582 14.253 24.7582H7.12406C6.14383 24.7582 5.34182 23.7174 5.34182 22.4453V20.1325H3.55958C2.57935 20.1325 1.77734 19.0917 1.77734 17.8196V13.1938C1.77734 11.2741 2.97144 9.7245 4.4507 9.7245ZM8.01487 22.4457H13.3616C13.8517 22.4457 14.2527 21.9253 14.2527 21.2892V16.6635H7.12375V21.2892C7.12375 21.9253 7.52475 22.4457 8.01487 22.4457ZM16.9268 14.3505C16.4367 14.3505 16.0357 13.8301 16.0357 13.194C16.0357 12.558 16.4367 12.0376 16.9268 12.0376C17.4169 12.0376 17.8179 12.558 17.8179 13.194C17.8179 13.8301 17.4169 14.3505 16.9268 14.3505Z" fill="white" />
                    </svg>
                    <p>  (123) 456-7891</p>
                </div> */}
                <div className="contacts-social-media">

                    {/* twitter */}
                    <a href="https://x.com/ripplenexus" target="_blank" rel='noreferrer'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.4043 8.3836L18.538 0.263184H16.8482L10.6514 7.31258L5.70568 0.263184H0L7.48049 10.9241L0 19.4386H1.68986L8.22962 11.9926L13.4538 19.4386H19.1594M2.29977 1.5111H4.89588L16.8469 18.252H14.2502" fill="#D2D2D2" />
                        </svg>
                    </a>

                    {/* instagram */}
                    <a href="https://www.instagram.com/ripplenexus/" target="_blank" rel='noreferrer'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_81_1845)">
                                <path d="M10.1854 1.99137C12.7453 1.99137 13.0489 2.00096 14.0604 2.0473C16.6587 2.16554 17.8723 3.39836 17.9906 5.97746C18.0369 6.98816 18.0457 7.29177 18.0457 9.85169C18.0457 12.4124 18.0361 12.7152 17.9906 13.7259C17.8715 16.3026 16.6611 17.5378 14.0604 17.6561C13.0489 17.7024 12.7469 17.712 10.1854 17.712C7.62544 17.712 7.32183 17.7024 6.31113 17.6561C3.70647 17.537 2.49922 16.2986 2.38097 13.7251C2.33463 12.7144 2.32504 12.4116 2.32504 9.85089C2.32504 7.29097 2.33543 6.98816 2.38097 5.97666C2.50002 3.39836 3.71046 2.16475 6.31113 2.0465C7.32263 2.00096 7.62544 1.99137 10.1854 1.99137ZM10.1854 0.263184C7.5815 0.263184 7.25552 0.274369 6.23283 0.32071C2.7509 0.480505 0.815777 2.41243 0.655981 5.89756C0.608842 6.92105 0.597656 7.24703 0.597656 9.85089C0.597656 12.4548 0.608842 12.7815 0.655182 13.8042C0.814978 17.2862 2.7469 19.2213 6.23203 19.3811C7.25552 19.4274 7.5815 19.4386 10.1854 19.4386C12.7892 19.4386 13.116 19.4274 14.1387 19.3811C17.6174 19.2213 19.5573 17.2894 19.7147 13.8042C19.7619 12.7815 19.7731 12.4548 19.7731 9.85089C19.7731 7.24703 19.7619 6.92105 19.7155 5.89836C19.5589 2.41962 17.6246 0.481304 14.1395 0.321509C13.116 0.274369 12.7892 0.263184 10.1854 0.263184ZM10.1854 4.9276C7.46645 4.9276 5.26208 7.13198 5.26208 9.85089C5.26208 12.5698 7.46645 14.775 10.1854 14.775C12.9043 14.775 15.1087 12.5706 15.1087 9.85089C15.1087 7.13198 12.9043 4.9276 10.1854 4.9276ZM10.1854 13.0468C8.42043 13.0468 6.98946 11.6166 6.98946 9.85089C6.98946 8.08595 8.42043 6.65499 10.1854 6.65499C11.9503 6.65499 13.3813 8.08595 13.3813 9.85089C13.3813 11.6166 11.9503 13.0468 10.1854 13.0468ZM15.3036 3.58293C14.6676 3.58293 14.1523 4.09827 14.1523 4.73345C14.1523 5.36864 14.6676 5.88398 15.3036 5.88398C15.9388 5.88398 16.4533 5.36864 16.4533 4.73345C16.4533 4.09827 15.9388 3.58293 15.3036 3.58293Z" fill="#D2D2D2" />
                            </g>
                            <defs>
                                <clipPath id="clip0_81_1845">
                                    <rect width="19.1754" height="19.1754" fill="white" transform="translate(0.597656 0.263184)" />
                                </clipPath>
                            </defs>
                        </svg>

                    </a>

                    {/* youtube */}

                    {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_81_1847)">
                            <path d="M15.8828 2.80702C13.0033 2.61048 6.58996 2.61127 3.71445 2.80702C0.600838 3.01955 0.234108 4.90034 0.210938 9.85079C0.234108 14.7925 0.597642 16.6812 3.71445 16.8946C6.59076 17.0903 13.0033 17.0911 15.8828 16.8946C18.9965 16.682 19.3632 14.8012 19.3864 9.85079C19.3632 4.90913 18.9996 3.02035 15.8828 2.80702ZM7.40172 13.0467V6.65489L13.7935 9.8452L7.40172 13.0467Z" fill="#D2D2D2" />
                        </g>
                        <defs>
                            <clipPath id="clip0_81_1847">
                                <rect width="19.1754" height="19.1754" fill="white" transform="translate(0.210938 0.263184)" />
                            </clipPath>
                        </defs>
                    </svg> */}


                    {/* linkedIn */}
                    <a href="https://www.linkedin.com/company/ripple-nexus/" target="_blank" rel='noreferrer'>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_81_1849)">
                                <path d="M16.0048 0.263184H4.8191C2.61312 0.263184 0.824219 2.05209 0.824219 4.25806V15.4437C0.824219 17.6497 2.61312 19.4386 4.8191 19.4386H16.0048C18.2115 19.4386 19.9996 17.6497 19.9996 15.4437V4.25806C19.9996 2.05209 18.2115 0.263184 16.0048 0.263184ZM7.21602 15.4437H4.8191V6.65499H7.21602V15.4437ZM6.01756 5.64189C5.24575 5.64189 4.61935 5.0107 4.61935 4.23249C4.61935 3.45429 5.24575 2.8231 6.01756 2.8231C6.78937 2.8231 7.41577 3.45429 7.41577 4.23249C7.41577 5.0107 6.79017 5.64189 6.01756 5.64189ZM16.8037 15.4437H14.4068V10.9663C14.4068 8.27531 11.2109 8.47905 11.2109 10.9663V15.4437H8.81397V6.65499H11.2109V8.06518C12.3263 5.99903 16.8037 5.84643 16.8037 10.0434V15.4437Z" fill="#D2D2D2" />
                            </g>
                            <defs>
                                <clipPath id="clip0_81_1849">
                                    <rect width="19.1754" height="19.1754" fill="white" transform="translate(0.824219 0.263184)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </div>
            </div >
        </>
    )
}

export default ContactInfo;